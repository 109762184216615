/* Contact page styles */
.contact-container {
  padding: 80px 20px; /* Increased padding to add space around content */
  background-color: #1a1a1a; /* Darker background */
  color: #f5f5f5; /* Light text color */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  margin: 80px auto 20px auto; /* Added margin-top to ensure space between content and navbar, and margin-bottom for space above the footer */
}

.contact-container h2 {
  color: #ffcc00; /* Yellow color for heading */
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-container p {
  font-size: 20px;
  line-height: 1.6;
}

.contact-info {
  margin-top: 30px;
}

.contact-info a {
  color: #ffcc00; /* Yellow color for links */
  text-decoration: none;
  font-size: 20px;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
}

.contact-info li {
  margin-bottom: 10px;
}

.contact-info .icon {
  margin-right: 10px;
  vertical-align: middle;
}

.blog {
  padding: 20px;
  background: linear-gradient(to right, #333, #1a1a1a);
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.blog-content {
  max-width: 1200px;
  margin: 0 auto;
}

.blog-heading {
  font-size: 48px;
  color: #ffcc00;
  margin-bottom: 40px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.blog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  background: #222;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 20px;
}

.blog-card-title {
  font-size: 24px;
  color: #ffcc00;
  margin-bottom: 10px;
}

.blog-card-description {
  font-size: 16px;
  color: #ccc;
  margin-bottom: 20px;
}

.blog-card-link {
  color: #ffcc00;
  text-decoration: none;
  font-weight: bold;
}

.blog-card-link:hover {
  text-decoration: underline;
}

/* About page styles */
.about {
  padding: 80px 20px; /* Increased padding to add space above and below content */
  background-color: #2a2a2a; /* Dark background for contrast */
  color: #f5f5f5; /* Light text color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}

.about h2 {
  color: #ffd700; /* Gold color */
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.about p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.about p:last-of-type {
  margin-bottom: 0; /* Remove bottom margin for the last paragraph */
}

.about-container {
  padding: 20px;
  background-color: #1c1c1c; /* Slightly darker background to contrast */
  border-radius: 10px;
  border: 1px solid #333; /* Optional: Add a border for visual separation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 auto; /* Center horizontally */
  max-width: 700px; /* Limit maximum width */
}

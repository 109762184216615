/* Global styles */
body {
  background-color: #1c1c1c;
  color: #f5f5f5;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Header styles */
header {
  background-color: #2a2a2a;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  color: #ffd700; /* Gold color */
  font-size: 28px;
  margin: 0;
}

header h1 a {
  color: #ffd700; /* Gold color */
  text-decoration: none;
  transition: text-shadow 0.5s ease; /* Slow transition for the glow */
}

header h1 a:hover {
  text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 20px #ffd700; /* Neon glow effect */
}

/* Nav styles with glow effect */
nav a {
  color: #ffd700; /* Gold color */
  text-decoration: none;
  margin-left: 20px;
  font-size: 18px;
  transition: text-shadow 0.5s ease; /* Slow transition for the glow */
}

nav a:hover {
  text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 20px #ffd700; /* Neon glow effect on hover */
}

/* Main content styles */
main {
  flex: 1;
  padding-bottom: 20px; /* Add padding to ensure content doesn't touch the footer */
}

/* Profile styles */
.profile {
  text-align: center;
  padding: 60px 20px;
  margin: 0 auto;
  max-width: 800px;
}

.profile-pic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.quote {
  color: #f0e68c; /* Khaki color */
  font-size: 24px;
  font-style: italic;
  margin-bottom: 20px;
}

/* Social media logos */
.socials {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-logo {
  width: 40px; /* Adjust size as needed */
  height: 40px;
}

/* Resume page styles */
.resume {
  padding: 60px 20px;
  background-color: #2a2a2a;
  color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}

.resume h2 {
  color: #ffd700; /* Gold color */
  font-size: 36px;
  margin-bottom: 20px;
}

.resume p {
  font-size: 18px;
  line-height: 1.6;
}

.resume a {
  color: #ffd700; /* Gold color */
  text-decoration: none;
}

.resume a:hover {
  text-decoration: underline;
}

.resume-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px; /* Adjust height as needed */
  margin-top: 20px;
  margin-bottom: 40px; /* Added gap between viewer and footer */
}

iframe {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  height: 100%;
}

/* About page styles */
.about {
  padding: 80px 20px; /* Increased padding to add space above and below content */
  background-color: #2a2a2a;
  color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}

.about h2 {
  color: #ffd700; /* Gold color */
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.about p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.about-container {
  padding: 20px;
  background-color: #1c1c1c; /* Slightly darker background to contrast */
  border-radius: 10px;
  border: 1px solid #333; /* Optional: Add a border for visual separation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 auto; /* Center horizontally */
  max-width: 700px; /* Limit maximum width */
}

/* Footer styles */
footer {
  text-align: center;
  padding: 10px 20px; /* Thicker footer for better visibility */
  background-color: #2a2a2a;
  color: #f0e68c; /* Khaki color */
  flex-shrink: 0; /* Ensure footer stays at the bottom */
}

/* src/components/Projects.css */

.projects {
  padding: 20px;
  background-color: #1a1a1a; /* Dark background for contrast */
  color: #f8f8f8; /* Light text color */
}

.projects h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #ffcc00; /* Yellow highlight color */
}

.project-list {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of cards if needed */
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center cards horizontally */
}

.project-card {
  background: #333; /* Dark card background */
  border-radius: 10px;
  padding: 15px;
  width: 300px; /* Fixed width for cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f8f8f8;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.project-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #ffcc00; /* Yellow text for project titles */
}

.project-card p {
  font-size: 1em;
}

.learn-more-link {
  display: block;
  margin-top: 10px;
  color: #ffcc00; /* Yellow link color */
  text-decoration: none;
  font-weight: bold;
}

.learn-more-link:hover {
  text-decoration: underline;
}

/* Resume page styles */
.resume {
  padding: 60px 20px;
  background-color: #1a1a1a; /* Darker background */
  color: #f5f5f5; /* Light text color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 204, 0, 0.2); /* Yellowish shadow */
  max-width: 900px;
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}

.resume h2 {
  color: #ffcc00; /* Yellow color for headings */
  font-size: 36px;
  margin-bottom: 20px;
}

.resume p {
  font-size: 18px;
  line-height: 1.6;
}

.resume a {
  color: #ffcc00; /* Yellow color for links */
  text-decoration: none;
}

.resume a:hover {
  text-decoration: underline;
}

.resume-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px; /* Adjust height as needed */
  margin-top: 20px;
  margin-bottom: 40px; /* Added gap between viewer and footer */
}

iframe {
  border: 1px solid #ffcc00; /* Yellow border for iframe */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(255, 204, 0, 0.3); /* Yellowish shadow */
  max-width: 100%;
  height: 100%;
}

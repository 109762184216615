.project-detail {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.project-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; /* Center images horizontally */
}

.project-image {
  width: 100px; /* Set a fixed width for tiles */
  height: 75px; /* Set a fixed height for tiles */
  object-fit: cover; /* Ensure images cover the tile area */
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer; /* Indicate the image is clickable */
}

.project-image:hover {
  border-color: #ffcc00; /* Change border color on hover to yellow */
}

h1 {
  color: #ffcc00; /* Yellow color for the title */
}

h2 {
  border-bottom: 2px solid #ffcc00; /* Yellow underline for subtitles */
  padding-bottom: 5px;
  color: #ffcc00; /* Yellow color for subtitles */
}

.project-features,
.project-development-process,
.project-future-plans,
.project-more-info {
  margin-top: 20px;
}
